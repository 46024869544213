<template>
  <div class="models-location-form">
    {{ model.city }}
    <select name="office" :class="['models-location-form-select', model.idUserCollection && 'disabled']" @change="selectLocation">
      <option value="">Seleccionar sede</option>
      <option v-for="(location, index) in locations" :key="index" :value="location.officeName" :selected="location.officeName === model.office">
        {{ location.officeName }}
      </option>
    </select>
    <ModelsWorkshiftSelect :model="model" @selected="selectedWorkShift = $event" />
    <ModelsRoomsSelect v-show="show" :model="model" :location="selectedLocation" :workShift="selectedWorkShift" />
    <ModelsMonitorSelect :model="model" :location="selectedLocation" />
  </div>
</template>

<script>
export default {
  props: ["model"],
  components: {
    ModelsRoomsSelect: () => import("./ModelsRoomsSelect.vue"),
    ModelsMonitorSelect: () => import("./ModelsMonitorSelect.vue"),
    ModelsWorkshiftSelect: () => import("./ModelsWorkshiftSelect.vue"),
  },
  data: function () {
    return {
      show: true,
      selectedLocation: null,
      selectedWorkShift: null,
    };
  },
  methods: {
    selectLocation: function ({ target: { value } }) {
      if (this.model?.idUserCollection) return;
      this.selectedLocation = value;
      if (this.selectedLocation === "Satelite") {
        this.show = false;
      } else if (this.selectedLocation !== "Satelite") {
        this.show = true;
      }
    },
    getLocations: async function () {
      const { api: host } = this.$global.getApp("autologin");

      if (!this.$store.state.locations) {
        this.loading = true;
        this.$store.state.locations = "loading";
        try {
          const { data } = await this.$api.get(`${host}/offices`);
          this.$store.state.locations = data;
          this.filterLocations(data);
        } catch (error) {
          this.$swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      }
    },
    citykey: function (cityName) {
      return this.$global.normalizeString(cityName);
    },
    filterLocations: function (locations) {
      const NewLocations = {};
      locations?.map((location) => {
        NewLocations[this.citykey(location.cityName)] =
          typeof NewLocations[this.citykey(location.cityName)] != "undefined" && NewLocations[this.citykey(location.cityName)] instanceof Array
            ? NewLocations[this.citykey(location.cityName)]
            : [];

        NewLocations[this.citykey(location.cityName)].push(location);
      });
      this.$store.state.locations = NewLocations;
    },
  },
  beforeMount: function () {
    this.selectedWorkShift = this.model?.workShift?.name || "";
    this.selectedLocation = this.model.office;
    this.getLocations();
  },
  computed: {
    locations: function () {
      // let mainCities = ["medellin", "bogota", "cucuta", "pereira"];
      // return this.$store.state.locations && this.$store.state.locations[this.citykey(this.model.city)];
      return this.$store.state?.locations[this.citykey(this.model.city)] || [];
    },
  },
};
</script>

<style lang="scss">
.models-location-form {
  margin: $mpadding/1.7 0 0 0;
  .disabled {
    pointer-events: none;
    opacity: 0.8;
    color: gray;
  }
}
</style>
